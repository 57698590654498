import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodySemiBold } from '@components/styled/Typography';
const IconWithTextContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: `${props.theme.spacing_sizes.xs * 5}px`,
    maxWidth: `${props.theme.baseSize * 70}px`,
    flex: 1,
    justifyContent: 'center',
}));
const IconContainer = styled.div(props => ({
    width: `${props.theme.spacing_sizes.xs * 8.75}px`,
    height: `${props.theme.spacing_sizes.xs * 8.75}px`,
    borderRadius: '50%',
}));
const Text = styled(BodySemiBold)((props) => ({
    fontWeight: 600,
    fontSize: `${props.theme.baseSize * 4}px`,
    textAlign: 'center',
    lineHeight: '36px',
}));
const IconWithText = ({ IconElement, text, }) => (_jsxs(IconWithTextContainer, { children: [_jsx(IconContainer, { children: IconElement }), _jsx(Text, { children: text })] }));
export default IconWithText;
