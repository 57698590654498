import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { getNumberColor, numberToFixed2 } from '@utils/DisplayValueHelpers';
import { Body, BodySmall } from '@components/styled/Typography';
const Container = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
}));
const PercentageIncomeBadge = withPercentageIncomeBadge(BodySmall);
const IncomeColumn = ({ className = undefined, income, percentDifference, currency = 'usdt', variant = 'color-full', align = 'left', }) => {
    const theme = useTheme();
    // TODO: discuss
    const currencyDisplay = useMemo(() => 'USDT', [currency]);
    const incomeDisplay = useMemo(() => `${numberToFixed2(income)} ${currencyDisplay}`, [income, currencyDisplay]);
    const incomeColor = useMemo(() => {
        if (variant !== 'color-full') {
            return undefined;
        }
        return getNumberColor(income, theme);
    }, [income, variant, theme]);
    const alignItems = useMemo(() => {
        let result = 'start';
        switch (align) {
            case 'right':
                result = 'end';
                break;
            case 'left':
            default:
                result = 'start';
        }
        return result;
    }, [align]);
    return (_jsxs(Container, { className: className, style: {
            alignItems,
        }, children: [_jsx(Body, { color: incomeColor, children: incomeDisplay }), _jsx(PercentageIncomeBadge, { badgeVariant: 'plain', percentageIncome: percentDifference })] }));
};
export default IncomeColumn;
